.card-login {
    border-radius : 3em;
    padding: 2em;
    box-shadow: 1px 1px 1px #333;
}

.card-login .form-control, .card-login .form-select, .card-login .form-check-input {
    border: 2px solid #D0D0D0;
    border-radius: 14.5px;
    color: #253A67;
    font-size: 12px;
    height: 33.9px;
}

.form-control{
    min-width: 200px !important;
}