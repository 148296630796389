// Body
$body-bg: #fff;

// Typography
$font-family-sans-serif: 'Inter', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

html, body, #ticket-root {
    height: 100%;
    display: flex;

    flex: 1;
}

$transition-collapse:         height .35s ease;
$transition-collapse-width:   width .35s ease;

/*$primary: #253A67;*/







