@import '../../node_modules/react-toastify/dist/ReactToastify.min.css';

// Fonts
@import url('https://fonts.googleapis.com/css?family=Abel');
@import url('https://fonts.googleapis.com/css?family=Inter');
@import url('https://fonts.googleapis.com/css?family=Alfa+Slab+One');

@import '_variables';
@import '~bootstrap/scss/bootstrap';

// Bootstrap
@import '../../node_modules/bootstrap-icons/font/bootstrap-icons.css';

// Variables
//@import '../css/app.css';

#ticket-root {
  width: 50%;
}

.collapse {
  &:not(.show) {
    display: none;
  }
}

.collapsing {
  height: 0;
  overflow: hidden;
  @include transition($transition-collapse);

  &.collapse-horizontal {
    width: 0;
    height: auto;
    @include transition($transition-collapse-width);
  }
}

.bg-branco-login {
  background-color: #ffffff;
  background-image: url('/assets/logo-fundo.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 20px;
}

.card-login {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 48px;
  padding: 20px 20px 0px 20px;
  margin-bottom: 10px;
  max-width: 406px;
  margin: 0 auto;
}

.btn-azul-pequeno-redondo {
  width: 83.37px;
  height: 28.18px;
  background-color: #253a67;
  color: #ffffff;
  border-radius: 14.5px;
  font-size: 10px;
  font-family: 'Abel';
}

.btn-azul-pequeno-redondo:hover {
  color: #333;
}

.btn-azul-grande-redondo {
  width: 125px;
  height: 38px;
  background: #253a67;
  border-radius: 18px;
  border: none;
  color: #ffffff;
  font-family: 'Abel';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding-top: 5px;
  margin: 5px;
}

.btn-azul-grande-redondo:hover {
  color: #333;
}

.btn-azul-pequeno:hover {
  color: #333;
}

.btn-verde-grande {
  width: 220px;
  height: 40px;
  background-color: #01ff84;
  color: #253a67;
  border-radius: 8px;
  font-size: 15px;
  font-family: 'Abel';
  padding-top: 5px;
}

.btn-verde-escuro-medio {
  width: 160px;
  height: 40px;
  background-color: #5bca03;
  color: #ffffff;
  border-radius: 8px;
  font-size: 15px;
  font-family: 'Abel';
  padding-top: 5px;
}

.btn-verde-escuro-medio:hover {
  color: #333;
}

.btn-laranja-grande {
  width: 220px;
  height: 40px;
  background-color: #ff8a00;
  color: #253a67;
  border-radius: 8px;
  font-size: 15px;
  font-family: 'Abel';
  padding-top: 5px;
  font-weight: bold;
}

.btn-lilas-grande {
  width: 220px;
  height: 40px;
  background-color: #5a6bff;
  color: #ffffff;
  border-radius: 8px;
  font-size: 15px;
  font-family: 'Abel';
  padding-top: 5px;
  font-weight: bold;
}

.btn-lilas-grande:hover {
  color: #d8d6d6;
}

.btn-azul-medio {
  width: 160px;
  height: 40px;
  background-color: #253a67;
  color: #ffffff;
  border-radius: 8px;
  font-size: 15px;
  font-family: 'Abel';
  padding-top: 5px;
}

.btn-azul-medio:hover {
  color: #333;
}

.btn-azul-grande {
  width: 220px;
  height: 40px;
  background-color: #253a67;
  color: #ffffff;
  border-radius: 8px;
  font-size: 15px;
  font-family: 'Abel';
  padding-top: 5px;
}

.btn-azul-grande:hover {
  color: #333;
}

.btn-verde-pequeno {
  width: 110px;
  height: 40px;
  background-color: #01ff84;
  color: #253a67;
  border-radius: 8px;
  font-size: 15px;
  font-family: 'Abel';
  padding-top: 5px;
}

.btn-azul-pequeno {
  width: 110px;
  height: 40px;
  background-color: #253a67;
  color: #ffffff;
  border-radius: 8px;
  font-size: 15px;
  font-family: 'Abel';
  padding-top: 5px;
}

.btn-azul-largo {
  width: 212px;
  height: 21px;
  background-color: #5e82e5;
  color: #ffffff;
  font-size: 10px;
  border-radius: 0;
  padding-top: 2px;
  font-family: 'Abel';
}

.btn-azul-largo:hover {
  color: #ffffff;
}

.link-cinza:link,
.link-cinza:hover,
.link-cinza:active,
.link-cinza:visited {
  color: #d8d6d6;
  text-decoration: underline;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  font-family: 'Inter';
}

.btn-lateral {
  margin-top: 29px;
}

.avatar-login {
  height: 50px;
}

.logo-inicial {
  width: 267px;
  margin: 0 auto;
  margin-bottom: 100px;
  margin-top: 20px;
}

.avatar-login {
  width: 111px;
  height: 111px;
  margin: 0;
  padding: 0;
  margin: 0 auto;
  margin-bottom: 10px;
}

.avatar-topo {
  height: 40px;
  float: right;
  cursor: pointer;
}

.espaco-login {
  position: absolute;
  top: 20px;
}

.espaco-link-criar-conta {
  width: 212px;
  padding-left: 20px;
}

.espaco-inputs-login {
  width: 244.32px;
}

.texto-9-400-11-inter {
  font-size: 9px;
  font-weight: 400;
  line-height: 11px;
  font-family: 'Inter';
}

.texto-10-400-13-Abel {
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  font-family: 'Abel';
  font-style: normal;
}

.texto-11-400-13-inter {
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  font-family: 'Inter';
}

.texto-12-400-14-inter {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  font-family: 'Inter';
  font-style: normal;
}

.texto-12-400-14-Abel {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  font-family: 'Abel';
  font-style: normal;
}

.texto-14-400-14-inter {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  font-family: 'Inter';
}

.texto-14-400-18-Abel {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-family: 'Abel';
}

.texto-15-400-11-Abel {
  font-size: 15px;
  font-weight: 400;
  line-height: 11px;
  font-family: 'Abel';
}

.texto-12-400-11-alfa {
  font-size: 12px;
  font-weight: 400;
  line-height: 11px;
  font-family: 'Alfa Slab One';
}

.texto-14-400-11-alfa {
  font-size: 15px;
  font-weight: 400;
  line-height: 11px;
  font-family: 'Alfa Slab One';
}

.texto-15-400-18-Abel {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  font-family: 'Abel';
}

.texto-15-400-19-Abel {
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  font-family: 'Abel';
}

.texto-16-400-19-alfa {
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  font-family: 'Alfa Slab One';
}

.texto-16-400-38-alfa {
  font-size: 16px;
  font-weight: 400;
  line-height: 38px;
  font-family: 'Alfa Slab One';
}

.texto-18-400-38-alfa {
  font-size: 16px;
  font-weight: 400;
  line-height: 38px;
  font-family: 'Alfa Slab One';
}

.texto-18-400-20-inter {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-family: 'Inter';
}

.texto-20-900-22-inter {
  font-size: 16px;
  font-weight: 900;
  line-height: 22px;
  font-family: 'Inter';
}

.texto-20-400-22-inter {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  font-family: 'Inter';
}

.texto-20-400-22-abel {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  font-family: 'Abel';
}

.texto-20-400-25-abel {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  font-family: 'Abel';
}

.texto-22-400-32-alfa {
  font-size: 16px;
  font-weight: 400px;
  line-height: 32px;
  font-family: 'Alfa Slab One';
}

.texto-22-400-50-alfa {
  font-size: 16px;
  font-weight: 400px;
  line-height: 50px;
  font-family: 'Alfa Slab One';
}

.texto-25-400-27-inter {
  font-size: 22px;
  font-weight: 400;
  line-height: 27px;
  font-family: 'Inter';
}

.texto-25-400-27-Abel {
  font-size: 25px;
  font-weight: 400;
  line-height: 27px;
  font-family: 'Abel';
}

.texto-30-400-38-Abel {
  font-size: 30px;
  font-weight: 400;
  line-height: 38px;
  font-family: 'Abel';
}

.texto-35-400-48-alfa {
  font-size: 35px;
  font-weight: 400px;
  line-height: 48px;
  font-family: 'Alfa Slab One';
}

.texto-26-400-36-alfa {
  font-size: 26px;
  font-weight: 400px;
  line-height: 36px;
  font-family: 'Alfa Slab One';
}
.submenu {
  margin-left: 50px;
}

.collapsed .submenu {
  margin-left: 0px;
}

.main {
  background-color: #ffffff;
}

.content {
  padding-top: 0 !important;
}

.navbar {
  box-shadow: none;
}

.menu-configuracoes {
  display: inline;
  list-style: none;
  padding: 0;
  width: 100%;
  padding-left: 0px;
}

.menu-configuracoes li {
  float: left;
  padding-right: 1px;
}

.menu-configuracoes button {
  width: 140px;
  height: 40px;
  background: #c7cbd2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
  font-family: 'Abel';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding-top: 5px;
  margin: 5px;
}

.menu-eventos {
  display: inline;
  list-style: none;
  padding: 0;
  width: 100%;
  padding-left: 0px;
}

.menu-eventos li {
  float: left;
  padding-right: 1px;
}

.menu-eventos button {
  width: 125px;
  height: 38px;
  background: #c7cbd2;
  border-radius: 18px;
  border: none;
  color: #ffffff;
  font-family: 'Abel';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding-top: 5px;
  margin: 5px;
}

.retangulo-cinza {
  background-color: #fbfafa;
  padding: 10px;
  margin-bottom: 10px;
}

.titulo-retangulo-cinza {
  color: #253a67;
  /*margin-top: -22px;*/
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.icone-imagem {
  font-size: 100px;
}

.icone-alerta-formulario {
  font-size: 20px;
}

.table-taxas table {
  border-collapse: collapse !important;
  border-radius: 1em !important;
  overflow: hidden !important;
}

.table-taxas thead {
  background: #c8ced9;
  border-bottom-width: 2px;
  border-bottom-color: #ffffff;
  color: #253a67;
  height: 50px;
}

.table-taxas thead th {
  padding-left: 20px;
}

.table-taxas thead th:first-child {
  border-radius: 10px 0 0 10px;
}

.table-taxas thead th:last-child {
  border-radius: 0 10px 10px 0;
}

.table-taxas td {
  background: #ffffff;
  padding-left: 20px;
  border: none;
}

.table-sem-linha table {
  border-collapse: collapse !important;
  border-radius: 1em !important;
  overflow: hidden !important;
}

.table-sem-linha thead {
  background: #c8ced9;
  border-bottom-width: 2px;
  border-bottom-color: #ffffff;
  color: #253a67;
  height: 50px;
}

.table-sem-linha thead th {
  padding-left: 20px;
}

.table-sem-linha thead th:first-child {
  /*border-radius: 24px 0 0 24px;*/
}

.table-sem-linha thead th:last-child {
  /*border-radius: 0 24px 24px 0;*/
}

.table-sem-linha td {
  background: #ffffff;
  padding-left: 20px;
  border: none;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  font-family: 'Abel';
  color: #253a67;
}

.table-com-linha table {
  border-collapse: collapse !important;
  border-radius: 1em !important;
  overflow: hidden !important;
}

.table-com-linha thead {
  background: #c8ced9;
  border-bottom-width: 2px;
  border-bottom-color: #ffffff;
  color: #253a67;
  height: 50px;
}

.table-com-linha thead th {
  padding-left: 20px;
}

.table-com-linha thead th:first-child {
  /*border-radius: 24px 0 0 24px;*/
}

.table-com-linha thead th:last-child {
  /*border-radius: 0 24px 24px 0;*/
}

.table-com-linha td {
  background: #ffffff;
  padding-left: 20px;
  border: none;
  border-bottom: 1px solid #e3e3e3;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  font-family: 'Abel';
  color: #253a67;
}

.retangulo-cinza-arredondado {
  background-color: #fbfafa;
  padding: 10px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 10px;
  color: #253a67;
  border-radius: 22px;
}

.caminho-1 {
  color: #bcbcbc;
}

.caminho-2 {
  color: #253a67;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #253a67;
  border-color: #253a67;
}

.page-link {
  position: relative;
  display: block;
  color: #253a67;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.page-link:hover {
  color: #253a67;
}

.espaco-pie {
  width: 70px;
  height: 70px;
}

.rdw-editor-main {
  height: 100px !important;
  overflow: auto;
  /*box-sizing: border-box;*/
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.form-control {
  min-width: 280px !important;
}

.form-select {
  min-width: 200px !important;
}

.form-select-normal {
  min-width: 200px !important;
}

@media (max-width: 720px) {
  .form-control {
    width: 100% !important;
  }
  .form-select {
    width: 100% !important;
  }
  .form-select-normal {
    width: 100% !important;
  }
}

.line-height-0 {
  line-height: 0 !important;
}

.switch-box {
  .switch-box-input {
    position: relative;
    cursor: pointer;
  }

  input[type='checkbox'] {
    width: 80px;
    height: 37px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #acacac;
    border-radius: 3em;
    position: relative;
    outline: 0;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;

    .is-invalid {
      border: 1px solid red;
    }

    &:checked {
      background: #049850;

      &:after {
        left: calc(100% - 36.6px);
      }
    }

    &:after {
      position: absolute;
      content: '';
      top: 2px;
      left: 2.267px;
      width: 35px;
      height: 34px;
      background: #fff;
      z-index: 2;
      border-radius: 50%;
      -webkit-transition: all 0.35s;
      transition: all 0.35s;
    }
  }

  i {
    position: absolute;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
    z-index: 1;
    font-size: 35px;
    color: #fff;

    &.is_checked {
      left: 4%;
      top: 20%;
      -webkit-transform: translateX(190%) translateY(-30%) scale(0);
      transform: translateX(190%) translateY(-30%) scale(0);
    }

    &.is_unchecked {
      left: 54%;
      top: 19%;
      -webkit-transform: translateX(0) translateY(-30%) scale(1);
      transform: translateX(0) translateY(-30%) scale(1);
    }
  }

  input[type='checkbox']:checked + .is_checked {
    -webkit-transform: translateX(0) translateY(-29%) scale(1);
    transform: translateX(0) translateY(-29%) scale(1);
  }

  input[type='checkbox']:checked ~ .is_unchecked {
    -webkit-transform: translateX(-190%) translateY(-29%) scale(0);
    transform: translateX(-190%) translateY(-29%) scale(0);
  }

  .label {
    color: #888888;
  }
}

.text-blue {
  color: #253a67;
}

.e-edit-btn {
  button {
    width: 58px;
    height: 53px;
  }

  button.btn-remove {
    background: #b82e4c;
  }

  button.btn-outline-options {
    color: #253a67;
    border-color: #253a67;
  }

  i {
    font-size: 22px;
  }

  div.space {
    width: 20px;
  }
}

.line-height-none {
  line-height: 0 !important;
}

.card.card-tickets {
  border-color: #fff !important;

  .item-rounded {
    background: #f4f4f4;
    border-radius: 15px;
  }

  .btn-azul-grande.add-new {
    font-size: 17px;
    font-weight: 600;
  }
}
