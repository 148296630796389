@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* css abaixo foi adicionado para remover o bug React: ResizeObserver loop completed with undelivered notifications */
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
/* css acima foi adicionado para remover o bug React: ResizeObserver loop completed with undelivered notifications */

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00b2ff;
  color: #00b2ff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00b2ff;
  color: #00b2ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00b2ff;
  color: #00b2ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #00b2ff;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

html,
* {
  scroll-behavior: smooth !important;
  font-family: 'Poppins', 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #adadad;
  border-radius: 6px;
  transition: background 0.3s ease-in-out; /* Adiciona transição para um efeito suave */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0; /* Muda a cor quando o mouse passa sobre o handle */
}

body {
  overflow: auto;
  margin: 0px !important;
}

.sidebar:not(.collapsed) {
  transition: all 250ms ease-in;
}

.sidebar.collapsed {
  margin-left: 0px;
  max-width: 80px;
  min-width: 80px;
  transition: all 250ms ease-out;
}

.sidebar.collapsed li b {
  justify-content: center;
}

.sidebar.collapsed li b span {
  display: none;
}

.sidebar.collapsed li b i {
  margin-right: 0 !important;
}

.sidebar-content.js-simplebar {
  background: #fff;
}

.sidebar-link,
.sidebar-link i {
  color: #333 !important;
}

.sidebar-link {
  background: #fff !important;
}
.sidebar-icon-item {
  display: none;
}
.collapsed .sidebar-icon-item {
  display: block;
}

.sidebar-header {
  color: #333;
  padding: 0.3rem 0.75rem;
  cursor: pointer;
  transition: all 100ms ease;
  margin: 8px 0;
}

.sidebar-header:hover {
  background-color: #00b2ff !important;
  border-radius: 8px;
  color: white !important;
  transition: all 50ms ease;
}

.collapased-custom {
  max-width: 80px;
}
.sidebar-link:hover {
  opacity: 0.8;
}

.sidebar-item:hover {
  border-left: 6px solid #00b2ff;
}

.sidebar.collapsed .sidebar-link span {
  display: none;
}

.sidebar.collapsed .sidebar-item.active {
  border-left: 3px solid #06a0fd;
}

@media only screen and (max-width: 600px) {
  .sidebar.collapsed {
    margin-left: 0px;
    max-width: 0px;
    min-width: 0px;
    transition: all 250ms ease-out;
  }
}

.dropdown-menu {
  background-color: #f8f7f7;
  margin-top: 0;
}

.dropdown-item {
  background-color: #f8f7f7;
}

.dropdown-item:hover {
  background-color: #c7cbd2;
}

/* calendar */

input:focus {
  caret-color: #00b2ff;
  border: 1px solid #00b2ff !important;
  box-shadow: 0px 0px 0px #00b2ff;
  outline-offset: 2px;
  outline: none;
}

input[disabled]::placeholder {
  color: rgba(34, 34, 34, 0.24);
}

input[disabled] {
  cursor: url('../assets/svgs/common/disabledIcon.svg'), not-allowed !important;
}

.disabled-button-cursor {
  cursor: url('../assets/svgs/common/disabledIcon.svg'), not-allowed !important;
}

.dateinput:focus {
  caret-color: #00b2ff;
  border: 1px solid transparent !important;
  box-shadow: 0px 0px 0px #ffffff;
  outline-offset: 2px;
  outline: none;
}

textarea:focus {
  border: 1px solid #00b2ff !important;
  box-shadow: 0px 0px 0px #00b2ff;
  outline-offset: 2px;
  outline: none;
}
